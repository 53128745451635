import logo from './logo.svg';
import 'skeleton-css/css/skeleton.css'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Would you sell your sol for some <b>creep toes</b>?</p>
        <p>Get some non-fungible <b>toe</b> kins</p>
        <a class="button button-primary" href="https://forms.gle/d4JBRVo9CxkmnJts7">get one</a>
        <a
          className="App-link"
          href="http://twitter.com/_creeptoes"
          target="_blank"
          rel="noopener noreferrer"
        >
          twitter
        </a>
      </header>
    </div>
  );
}

export default App;
